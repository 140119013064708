@font-face {
  font-family: "AvenirNextBold";
  src: url("../assets/fonts/AvriNextFont/avenir-next-bold.eot");
  src: url("../assets/fonts/AvriNextFont/avenir-next-bold.eot")
      format("embedded-opentype"),
    url("../assets/fonts/AvriNextFont/avenir-next-bold.woff") format("woff"),
    url("../assets/fonts/AvriNextFont/avenir-next-bold.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNextRegular";
  src: url("../assets/fonts/AvriNextFont/avenir-next-regular.eot");
  src: url("../assets/fonts/AvriNextFont/avenir-next-regular.eot")
      format("embedded-opentype"),
    url("../assets/fonts/AvriNextFont/avenir-next-regular.woff") format("woff"),
    url("../assets/fonts/AvriNextFont/avenir-next-regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "AvenirNextThin";
  src: url("../assets/fonts/AvriNextFont/avenir-next-thin.eot");
  src: url("../assets/fonts/AvriNextFont/avenir-next-thin.eot")
      format("embedded-opentype"),
    url("../assets/fonts/AvriNextFont/avenir-next-thin.woff") format("woff"),
    url("../assets/fonts/AvriNextFont/avenir-next-thin.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNextDemi";
  src: url("../assets/fonts/AvriNextFont/avenir-next-demi.eot");
  src: url("../assets/fonts/AvriNextFont/avenir-next-demi.eot")
      format("embedded-opentype"),
    url("../assets/fonts/AvriNextFont/avenir-next-demi.woff") format("woff"),
    url("../assets/fonts/AvriNextFont/avenir-next-demi.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirNextMedium";
  src: url("../assets/fonts/AvriNextFont/AvenirLTPro-Medium.eot");
  src: url("../assets/fonts/AvriNextFont/AvenirLTPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AvriNextFont/AvenirLTPro-Medium.woff") format("woff"),
    url("../assets/fonts/AvriNextFont/AvenirLTPro-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}

.font-12 {
  font-size: 12px !important;
}

.thin-font {
  font-family: $thin-font !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-19 {
  font-size: 19px !important;
}

.primary-color,
.primary-font {
  color: $primary-color !important;
}

.bold-font {
  font-family: $bold-font !important;
}

.semi-bold-font {
  font-family: $secondary-font !important;
}

.primary-border {
  border-color: $primary-color !important ;
}

.text-decoration-none {
  text-decoration: none !important;
}
