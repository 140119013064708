.custom-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
    &.sm {
        height: 35px;
        width: 35px;
    }
    &.round {
        border-radius: 50%;
    }
}

.custom-img-profile {
    width: 112px;
    height: 112px;
    object-fit: cover;
    border-radius: 7px;
    &.sm {
        height: 35px;
        width: 35px;
    }
    &.round {
        border-radius: 50%;
    }
}
