.custom-modal-container {
  .modal-content {
    border: none;
    text-align: center;

    .modal-header {
      background-color: $primary-color;
      color: #fff;
      line-height: 58.7px;
      border-bottom: none;
      min-height: 53px;

      .modal-title {
        width: 100%;
        font-weight: 600;
        font-size: 18px;
      }
    }

    .modal-body {
      color: #333333;
      font-size: 15px;
    }

    .modal-footer {
      border-top: none;
      justify-content: center;
      padding-bottom: 20px;
    }
  }
}

.modal-min-width {
  min-width: 200px !important;
}
