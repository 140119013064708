$primary-font: AvenirNextRegular;
$bold-font: AvenirNextBold;
$demi-font: AvenirNextDemi;
$secondary-font: AvenirNextMedium;
$thin-font: AvenirNextThin;

$grey-background: rgb(245, 248, 249);
$grey-border: rgba(151, 151, 151, 0.3);
$orange-gradient-bg: linear-gradient(to bottom right, white, #1E3145);

$black-color: #313230;
$black-dark-color: #222321;
$black-light-color: #5A5B59;
$primary-color: #2b4763;
$primary-dark-color: #1E3145;
$primary-light-color: #556B82;
$white-color: #ffffff;
$red-color: rgb(221, 44, 0);
$grey-color: rgb(153, 153, 153);
$green-color: green;
$green-light-color: #11c153;
$gray-dark-color: #7f7f7f;
$gray-light-color: #e6e6e6;
$grey-white-color: #bfbfbf;

$hover-color-table: rgba(0, 0, 0, 0.075);
