.sm-sidebar {
    width: 100%;
    height: 100%;
    .list {
        border: 1px solid $grey-white-color;
        box-shadow: 0 2px 2px $grey-white-color;
        background: $white-color;
        border-radius: 5px;
        padding: 0;
        .list-item {
            .css-cveggr-MuiListItemIcon-root {
                color: inherit !important;
            }

            img {
                width: 25px;
                padding-left: 4px;
                text-align: center;
                // filter: contrast(0.46);
            }

            &.Mui-selected {
                background-color: $primary-color !important;
                color: $white-color !important;
                .MuiListItemIcon-root {
                    color: $white-color !important;
                }
                img {
                    filter: invert(1);
                }
            }
        }

        .list-item:first-child {
            border-top-left-radius : 5px;
            border-top-right-radius : 5px;
        }

        .list-item:last-child {
            border-bottom-right-radius : 5px;
            border-bottom-left-radius : 5px;
        }
    }
}

.bottom-nav-grid {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 0;
    padding-left: 0px !important;
    .bottom-nav {
        width: 100%;
        box-shadow: 0 -2px 2px $grey-white-color;
    }
}
