.login-page {
    z-index: 1;
    position: relative;
    text-align: center;

    .small-logo-container{
        margin-left: auto !important;
        margin-right: auto !important;
        padding: 10px;
        position: absolute;
        background: white;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        top: -17px;
        .small-logo{
            height: 50px;
        }
    }
}
