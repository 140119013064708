.medicine-bg-ui {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    .medicine-icon{
        position: absolute;
        height: 40px;
        opacity: 0.1;
        &.icon3{
            left: 50%;
            bottom: 30%;
            height: 120px;
        }
        &.icon4{
            left: 20%;
            bottom: 40%;
            height: 110px;
        }
        &.icon5{
            left: 30%;
            bottom: 10px;
            height: 80px;
        }
        &.icon6{
            left: 80%;
            bottom: 65%;
            height: 75px;
        }
        &.icon7{
            left: 10%;
            top: 40%;
            height: 80px;
        }
        &.icon8{
            left: 72%;
            bottom: 20%;
            height: 80px;
        }
        &.icon9{
            left: 10%;
            bottom: 20%;
            height: 100px;
        }
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.block {
    display: block !important;
}

p.MuiTypography-root{
    text-transform: none !important;
}

.d-none {
    display: none !important;
}

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column!important;
}

.border-bottom{
    border-bottom: thin solid $gray-light-color !important;
}

.mx-auto {
    margin: 0 auto;
}

.border-bottom-thick{
    border-bottom: medium solid $gray-light-color !important;
}

.w-50 {
    width: 50%;
}

.w-70 {
    width: 70%;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.ml-10 {
    margin-left: 10px;
}

.pointer {
    cursor: pointer;
}

.clearfix:after {
    display: block;
    clear: both;
    content: "";
}

.primary-border {
    border-color: $primary-color !important ;
}

.primary-text {
    color: $primary-color !important;
}

.clearfix {
    overflow: auto;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.div-align-left {
    text-align: left;
}

.p-bold {
    font-weight: bold;
}

.font-22 {
    font-size: 22px !important;
}

.text-center {
    text-align: center;
}

.p-10 {
    padding: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

button {
    text-transform: none !important;
}

.footer {
    padding: 10px;
    background-color: $white-color;
    box-shadow: 0 0px 3px #9a9595;
}

.floor-seperator {
    border: 1px solid $black-color;
    border-radius: 5px;
    padding: 10px;
}

.room-btn-height {
    min-height: 60px;
}

.gujarati-font-model {
    .MuiDialogTitle-root {
        font-size: 25px;
    }
    .custom-model-body {
        p {
            font-size: 22px;
        }
        font-size: 22px;
    }
    .model-footer-btn {
        button {
            font-size: 22px;
        }
    }
}

.dashboard-tabs-container {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $grey-color;
    padding-bottom: 10px;
    a {
        font-size: 20px;
    }
}

.submit-button, .insta-button {
    min-width: 150px !important;
}

.table-paper {
    &.sticky-table {
        .table-header {
            width: 100%;
            overflow-x: auto;
            max-height: 75vh;
            .table {
                .sticky-column {
                    position: sticky;
                    left: 0;
                    background-color: $gray-light-color;
                    border-right: 1px solid $gray-light-color;
                    z-index: 3;
                    min-width: 100px;
                }
                .table-head {
                    tr {
                        th {
                            background-color: $gray-light-color;
                            z-index: 5 !important;
                        }
                    }
                }

                .table-body {
                    tr {
                        td {
                            border: 1px solid $gray-light-color;
                        }
                    }
                }
                
            }
        }
    }
}

.room-available {
    background-color: $green-light-color !important;
}

.room-not-available {
    background-color: $red-color !important;
}

.record-btn, .pause-record-btn, .audio-play-btn {
    svg {
        font-size: 40px;
    }
}

.expense-list-item {
    .expense-list-item-box {
        border: 1px solid $primary-light-color;
        padding: 5px;
        border-radius: 5px;
    }
}

.expense-type-label {
    background: $primary-light-color;
    border-radius: 10px;
    padding: 10px;
    color: $white-color !important;
    text-align: center;
}

.expense-img-list {
    .img-item {
        border: 1px solid $primary-light-color;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        &:hover {
            filter: brightness(0.8);
        }
    }
}