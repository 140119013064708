.dashboard-page {
    z-index: 1;
}

.stop-smoking-img-container{
    overflow: hidden;
    position: relative;

    .hover-div{
        height: 98%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        cursor: pointer;
        .hide-div{
            text-align: center;
            display: flex !important;
            justify-content: center;
            align-items: center;
            height: 100%;
            .hover-div-container{
                .assessment-icon{
                    width: 40px;
                    filter: invert(1);
                    margin-bottom: 8px;
                    cursor: pointer;
                    color: white;
                }
                .assessment-text{
                    color: white;
                    font-size: 26px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }

    .stop-smoking-img{
        filter: brightness(0.4);
    }

    &:hover {
        .stop-smoking-img{
            transition: all ease-in-out 0.2s;
            filter: brightness(0.48);
            transform: scale(1.02);
            cursor: pointer;
        }
        .hover-div {
            display: block;
            cursor: pointer;
        }
    }
}



.stop-smoking-text{
    min-height: 105px;
    align-items: center;
    text-align: center;
    display: flex;
}
