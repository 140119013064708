.auth-layout-grid {
    margin-left: 0px !important;
    width: 100% !important;
    .auth-nav-page-grid {
        padding: 10px;
        min-height: 100vh;
        .auth-nav-pages-container {
            background-color: $white-color;
            width: 100%;
            border-radius: 5px;
            border: 1px solid $grey-white-color;
            box-shadow: 0 2px 2px $grey-white-color;
            z-index: 1;
        }
    }
}