@import "./styles/variables.scss";
@import "./styles/fonts.scss";

body,
html {
  font-family: $primary-font;
  overflow-x: hidden;
  background-color: $grey-background;
  padding-right: 0px !important;
  margin: 0 !important;
}

// Common SCSS
@import "./styles/containers/common.scss";
@import "./styles/containers/layout.scss";

// Elements
@import "./styles/elements/button.scss";
@import "./styles/elements/customModal.scss";
@import "./styles/elements/image.scss";
@import "./styles/elements/inputs.scss";
@import "./styles/elements/labels.scss";

// Containers
@import "./styles/containers/topnav.scss";
@import "./styles/containers/sidebar.scss";
@import "./styles/containers/incompleteProfileForm.scss";

// pages
@import "./styles/pages/login.scss";
@import "./styles/pages/dashboard.scss";

// media query
@import "./styles/mediaQuery.scss";
