.topnav {
    header {
        background-color: $white-color;
        color: $primary-color;
        .topnav-logo {
            height: 50px;
        }
    }
}

// user top nav avatar menu
.menu{
    .menu-item {
        min-height: 50px;
    }
}


.user-top-nav-avatar-dd {
    .user-icon-dd{
        &:hover, &:active, &:focus {
            border-radius: 10px !important;
        }
    }
}