.insta-button {
    text-transform: capitalize !important;
    min-width: 150px;
}

.btn-text-gray {
    color: $gray-dark-color !important;
}

.green {
    background-color: $green-light-color !important;
}

.red {
    background-color: $red-color !important;
}