.insta-label {
  font-family: $primary-font;
  font-size: 15px;
  margin-left: 0;
  color: $black-color;
  opacity: 0.9;
}

.insta-label.small {
  font-size: 14px;
  font-family: $primary-font;
  color: $black-light-color;
  margin-top: 10px;
}

.insta-label.red, .insta-label.red-text {
  font-size: 15px;
  font-family: $secondary-font;
  color: $red-color;
}

.insta-label.small.gray {
  color: $grey-color;
}

.insta-label.ex-small {
  font-size: 12px;
  font-family: $primary-font;
  color: $grey-color !important;
  margin-top: 10px;
}

.insta-label.big {
  font-size: 25px;
}

 .insta-label.gray {
   color: $gray-dark-color;
   font-family: $primary-font;
 }

 .insta-label.light-gray {
   color: $grey-color;
   font-family: $thin-font;
 }

 .insta-label.green {
   color: green;
   font-family: $primary-font;
 }

 .insta-label.light {
   opacity: 0.9;
 }

 .insta-label.error {
   color: $red-color;
   font-size: 0.75rem;
   margin-top: 8px;
   min-height: 1em;
   text-align: left;
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   font-weight: 400;
   line-height: 1em;
   letter-spacing: 0.03333em;
 }

 .text-shadow {
   text-shadow: 0 0 0 black;
 }

 .asterisk {
   color: $red-color;
 }

 .insta-label.secondary-font {
   font-family: $secondary-font;
 }
